import { YES_NO_UNKNOWN, NUMBER_INPUT_POSITIVE } from '@/forms/schemas/generators'
import {
  CAVITY_WALL_OPTIONS,
  ENVIRONMENT_OPTIONS,
  ORIENTATION_OPTIONS,
  PROPERTY_STATES,
  ROOF_TYPE_OPTIONS
} from '@/forms/selectOptions'
import { getPropertyTechnical, updatePropertyTechnical } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

const schema = [
  {
    component: 'h2',
    children: 'Oppervlaktes en afmetingen'
  },
  {
    component: 'div',
    class: 'tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        name: 'surface_plot',
        label: 'Perceeloppervlakte',
        placeholder: 'Perceeloppervlakte (m²)',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'surface_built',
        label: 'Bebouwde oppervlakte',
        placeholder: 'Bebouwde oppervlakte (m²)',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'surface_livable',
        label: 'Bewoonbare oppervlakte',
        placeholder: 'Bewoonbare oppervlakte (m²)',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'surface_trading',
        label: 'Handelsoppervlakte',
        placeholder: 'Handelsoppervlakte (m²)',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'surface_plot_depth',
        label: 'Perceeldiepte',
        placeholder: 'Perceeldiepte',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'surface_plot_width',
        label: 'Perceelbreedte',
        placeholder: 'Perceelbreedte',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'facade_width',
        label: 'Gevelbreedte',
        placeholder: 'Gevelbreedte',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      }
    ]
  },
  {
    component: 'h2',
    children: 'Bouw- en renovatiejaar'
  },
  {
    component: 'div',
    class: 'tw-mt-4 tw-my-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        name: 'build_year',
        label: 'Bouwjaar',
        placeholder: 'Bouwjaar',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'renovation_year',
        label: 'Renovatiejaar',
        placeholder: 'Renovatiejaar',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'renovation_description',
        label: 'Details renovatie',
        type: 'translatable',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      }
    ]
  },
  {
    component: 'h2',
    children: 'Overige'
  },
  {
    component: 'div',
    class: 'tw-mt-4 tw-my-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        name: 'build_obligation',
        label: 'Bouwverplichting',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'building_line',
        label: 'Rooilijn',
        placeholder: 'Rooilijn',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'measures_of_expropriation',
        label: 'Maatregelen van onteigening',
        placeholder: 'Maatregelen van onteigening',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'construction_violations',
        label: 'Bouwovertredingen begaan/vastgesteld',
        placeholder: 'Bouwovertredingen begaan/vastgesteld"',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        type: 'select',
        name: 'building_type',
        label: 'Bebouwing',
        placeholder: 'Bebouwing',
        options: {
          0: 'Onbekend',
          1: 'Open',
          2: 'Half open',
          3: 'Gesloten'
        },
        outerClass: 'tw-m-0'
      },
      {
        name: 'cornice_height',
        label: 'Kroonlijsthoogte',
        placeholder: 'Kroonlijsthoogte',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        type: 'select',
        name: 'environment',
        label: 'Omgeving',
        placeholder: 'Selecteer omgeving',
        options: ENVIRONMENT_OPTIONS,
        outerClass: 'tw-m-0'
      },
      {
        name: 'floor',
        label: 'Verdieping',
        placeholder: 'Verdieping',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'number_of_floors',
        label: 'Aantal verdiepingen',
        placeholder: 'Aantal verdiepingen',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        type: 'select',
        name: 'parking_spot_type',
        label: 'Type parkeerplaats',
        placeholder: 'Type parkeerplaats selecteren',
        options: {
          0: 'Onbekend',
          1: 'Garage',
          2: 'Carport',
          3: 'Staanplaats'
        },
        outerClass: 'tw-m-0'
      },
      {
        name: 'parking_spots',
        label: 'Aantal parkeerplaatsen',
        placeholder: 'Aantal parkeerplaatsen',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        type: 'checkbox',
        name: 'is_garage_sale_possible',
        label: 'Aankoop garage mogelijk',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        name: 'roof_description',
        label: 'Dakbeschrijving',
        type: 'translatable',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        name: 'roof_slope',
        label: 'Dakhelling',
        placeholder: 'Dakhelling',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        type: 'select',
        name: 'roof_type',
        label: 'Daktype',
        placeholder: 'Kies daktype',
        options: ROOF_TYPE_OPTIONS,
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'cavity_wall',
        label: 'Spouwmuur',
        placeholder: 'Kies spouwmuur',
        options: CAVITY_WALL_OPTIONS,
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'sea_view',
        label: 'Zeezicht',
        placeholder: 'Zeezicht',
        options: {
          0: 'Onbekend',
          1: 'Ja',
          3: 'Zijdelings',
          2: 'Nee'
        },
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'state',
        label: 'Staat',
        placeholder: 'Selecteer staat',
        options: PROPERTY_STATES,
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'on_ground_floor',
        label: 'Gelijkvloers',
        placeholder: 'Is gelijkvloers',
        options: {
          0: 'Onbekend',
          1: 'Ja',
          2: 'Nee'
        },
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'ready_to_go',
        label: 'Instapklaar',
        placeholder: 'Is het instapklaar',
        options: {
          0: 'Onbekend',
          1: 'Ja',
          2: 'Nee'
        },
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'orientation',
        label: 'Oriëntatie',
        options: ORIENTATION_OPTIONS,
        outerClass: 'tw-m-0'
      }
    ]
  }
]

const actions = {
  async load (propertyId) {
    try {
      const response = await getPropertyTechnical(propertyId)
      const { state, ...payload } = response.data
      return { state: state.id, ...payload }
    } catch (error) {
      errorModal('Fout bij het laden van technische gegevens, probeer het opnieuw.')
      throw error
    }
  },
  async submit (propertyId, data) {
    try {
      const { orientation, ...payload } = data
      // BE does not accept empty string, select only outputs empty string for no selection/value.
      payload.orientation = orientation === '' ? null : orientation

      const response = await updatePropertyTechnical(propertyId, payload)
      successModal('De wijzigingen aan de technische gegevens zijn succesvol opgeslagen')
      return response
    } catch (error) {
      errorModal('Er ging iets fout bij het wijzigen van de technische gegevens van dit pand. Gelieve nogmaals te proberen.')
      throw error
    }
  }
}

export default {
  heading: 'Technische gegevens',
  schema,
  actions
}
